import { Grid, Typography } from "@material-ui/core/"
import { graphql, navigate, useStaticQuery } from "gatsby"
import * as React from "react"
import theme from "../App.theme"
import ButtonComponent from "../components/_common/Button/Button"

import { Layout, SectionContainer, SectionTitle, TextImageSection } from "../components/_layout"
import {
  AcercaDeLaPlataforma,
  ComiteSection,
  ConsejoNacionalSection,
  InclusionSection
} from "../components/AcercaModule"
import { Contact } from "../components/ContactModule"
import { Newsletter, ThanksSection } from "../components/homepage"
import { ISponsorImage } from "../components/homepage/ThanksSection/ThanksSection"
import { ISectionContentImage } from "./index"

const getHomepageautor = graphql`
    query {
        sponsorData: allContentfulColaboradoresLogotipo(
            sort: { fields: [ordenDeAparicion], order: ASC }
            filter: { seccionAcercaDe: { eq: true } }
        ) {
            edges {
                node {
                    nombre
                    logotipo {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        consejoDataImages: allContentfulColaboradoresLogotipo(
            sort: { fields: [ordenConsejoNacional], order: ASC }
            filter: { seccionConsejoNacional: { eq: true } }
        ) {
            edges {
                node {
                    nombre
                    logotipo {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        comiteDataImages: allContentfulColaboradoresLogotipo(
            sort: { fields: [ordenComiteDeEducacionFinanciera], order: ASC }
            filter: { seccionComiteDeEducacion: { eq: true } }
        ) {
            edges {
                node {
                    nombre
                    logotipo {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        inclusionData: allContentfulContenidoAcercaLaInclusionFinanciera {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }

        #        politicaData: allContentfulContenidoAcercaLaPoliticaNacional {
        #            edges {
        #                node {
        #                    titulo
        #                    contenido {
        #                        raw
        #                    }
        #                }
        #            }
        #        }

        consejoData: allContentfulContenidoAcercaElConsejoNacional {
            edges {
                node {
                    titulo
                    intro {
                        intro
                    }
                    notas {
                        notas
                    }
                    contenido {
                        raw
                    }
                }
            }
        }

        comiteData: allContentfulContenidoAcercaElComiteDeInclusion {
            edges {
                node {
                    titulo
                    intro {
                        intro
                    }
                    notas {
                        notas
                    }
                    contenido {
                        raw
                    }
                }
            }
        }

        acercaData: allContentfulContenidoAcercaDeAcercaDeEstaPlataforma {
            edges {
                node {
                    titulo
                    contenido {
                        raw
                    }
                    imagen {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
    }
`

export interface ISectionContent {
  titulo: string
  contenido: string
}

export interface ISectionContentExtended extends ISectionContent {
  intro: string
  notas: string
}

const Acerca: React.FC = () => {
  const {
    inclusionData,
    // politicaData,
    consejoData,
    comiteData,
    consejoDataImages,
    comiteDataImages,
    acercaData
  } = useStaticQuery(getHomepageautor)

  const acerca: ISectionContentImage = acercaData.edges.map(({ node }: any) => ({
    ...getSectionData(acercaData),
    image: node.imagen.fluid,
    alt: node.titulo.split(" ").join("-").toLowerCase()
  }))[0]

  const miembrosConsejo: ISponsorImage[] = consejoDataImages.edges.map(
    ({ node }: any) => ({
      nombre: node.nombre,
      image: node.logotipo.fluid,
      alt: node.nombre.split(" ").join("-").toLowerCase()
    })
  )

  const comiteEducacionLogos: ISponsorImage[] = comiteDataImages.edges.map(
    ({ node }: any) => ({
      nombre: node.nombre,
      image: node.logotipo.fluid,
      alt: node.nombre.split(" ").join("-").toLowerCase()
    })
  )

  const inclusion: ISectionContentImage = inclusionData.edges.map(({ node }: any) => ({
    ...getSectionData(inclusionData),
    image: node.imagen.fluid,
    alt: node.titulo.split(" ").join("-").toLowerCase()
  }))[0]

  const consejo: ISectionContentExtended = consejoData.edges.map(({ node }: any) => ({
    ...getSectionData(consejoData),
    intro: node.intro.intro,
    notas: node.notas.notas
  }))[0]

  const comite: ISectionContentExtended = comiteData.edges.map(({ node }: any) => ({
    ...getSectionData(comiteData),
    intro: node.intro.intro,
    notas: node.notas.notas
  }))[0]

  // const politica: ISectionContent = getSectionData(politicaData)

  return (
    <Layout withNav seoTitle="Acerca" seoDescription="Acerca de la PNIF">

      <AcercaDeLaPlataforma
        noButton
        sectionID={ "acerca-section" }
        title={ acerca.titulo }
        content={ acerca.contenido }
        img={ acerca.image }
        alt={ acerca.alt }
      />

      <InclusionSection sectionID="inclusion-section" { ...{ inclusion } } />

      {/*<PoliticaNacionalSection*/ }
      {/*  sectionID="politica-nacional-section"*/ }
      {/*  { ...{ politica } }*/ }
      {/*/>*/ }

      <ConsejoNacionalSection
        sectionID="consejo-nacional-section"
        { ...{ consejo, miembrosConsejo } }
      />

      <ComiteSection
        sectionID="comite-section"
        { ...{ comite, comiteEducacionLogos } }
      />

      <SectionContainer sectionID={ "glosario-frecuentes" }>
        <SectionTitle title={ "Para más información" } />
        <Grid container spacing={ 2 } justify={ "center" } style={ { padding: theme.spacing(4) } }>
          <Grid item><ButtonComponent goTo={ "/glosario" }>Glosario</ButtonComponent></Grid>
          <Grid item> <ButtonComponent goTo={ "/preguntas-frecuentes" }>Preguntas frecuentes</ButtonComponent></Grid>
        </Grid>
      </SectionContainer>

      <Contact />

      <Newsletter sectionID={ "about-newsletter" } />

    </Layout>
  )
}

export default Acerca

const getSectionData = (arr: any) =>
  arr.edges.map(({ node }: any) => ({
    titulo: node.titulo,
    contenido: node.contenido.raw
  }))[0]
